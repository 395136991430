import Replacement from "../../assets/svg/replacement.svg"

import FounderCard from "./Member Card/founder-card"
import TechCard from "./Member Card/tech-card"
import CreativeCard from "./Member Card/creative-card"
import OperationsCard from "./Member Card/op-card"

import Banner from "../home/Banner/Banner"

import "./styles.scss"

function Team() {
  return (
    <div className="lp-team-body">
      <div className="lp-team-wrapper">
        <Banner />
        <div className="lp-team-introduction">
          <p className="lp-team-introduction-title">Who We Are & Our Mission</p>
          <p className="lp-team-introduction-description">
            We're a diverse group that lives and breathes crypto. We believe the Web3 infrastructure is ready for a
            SocialFi application that can onboard the masses, and we are here to build it!
          </p>
        </div>
        <div className="lp-team-container">
          <div className="lp-team-category">
            <p className="lp-team-core-title founder">FOUNDERS</p>
          </div>
          <FounderCard />
        </div>

        <div className="lp-team-container">
          <div className="lp-team-category">
            <p className="lp-team-core-title tech">TECH</p>
          </div>
          <TechCard />
        </div>

        <div className="lp-team-container">
          <div className="lp-team-category">
            <p className="lp-team-core-title creative">CREATIVE</p>
          </div>
          <CreativeCard />
        </div>

        <div className="lp-team-container">
          <div className="lp-team-category">
            <p className="lp-team-core-title operations">OPERATIONS</p>
          </div>
          <OperationsCard />
        </div>
      </div>
    </div>
  )
}

export default Team
