import React from "react"

import HomeBtmArt from "../../../assets/home-bottom-art.png"

// import Button from "../../../components/Button/Button"

import "./styles.scss"
import { Mixpanel } from "../../../utils/mixpanel"
import { LP_Home_JoinWaitlist, other_source } from "../../../utils/mixpanel/mixpanelActionTypes"

function CTA() {
  return (
    <section className="lp-cta-container">
      <div className="lp-section-header">
        <div className="lp-heading">The Future of NFTs is in Your Hands!</div>
        <p className="text text-white">
          Rise above the internet trolls and show ‘em you’re not just a JPEG owner. Because on Torum V2, your NFTs are
          capable of so much more.
        </p>

        <a
          onClick={() => {
            Mixpanel.track(LP_Home_JoinWaitlist)
          }}
          href="https://bsu5ua93efj.typeform.com/to/CqTXTybp"
          target="_blank"
        >
          <div className="lp-cta-bottom-btn">Join Waitlist!</div>
        </a>
      </div>

      <img src={HomeBtmArt} className="lp-cta-img-art" alt="lp-cta-img-art" />
    </section>
  )
}

export default CTA
