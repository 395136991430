import React from "react"

import BannerIcon1 from "../../../assets/banner-icon-1.png"

import Button from "../../../components/Button/Button"

import "./Banner.scss"
import { NavLink } from "react-router-dom"
import { ROUTES } from "../../../constants/navigation"
import { Mixpanel } from "../../../utils/mixpanel"
import { LP_Home_FounderLetter, other_source } from "../../../utils/mixpanel/mixpanelActionTypes"

function Banner() {
  return (
    <section className="lp-banner-container">
      <div className="lp-section-header">
        <p className="text">Unveiling Torum V2</p>
        <h1 className="lp-heading">An Open Letter from the Founder: A New Chapter Begins</h1>
      </div>
      <div className="lp-section-footer">
        <NavLink
          to={ROUTES.LETTER.path}
          className="button button-white"
          onClick={() => {
            Mixpanel.track(LP_Home_FounderLetter)
          }}
        >
          Read now
        </NavLink>
      </div>

      <div className="lp-banner-icon-container">
        <img src={BannerIcon1} alt="home-banner-icon-1" />
      </div>
    </section>
  )
}

export default Banner
