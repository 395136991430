import React, { useEffect } from "react"

import Marquee from "react-fast-marquee"

import LetterBgArt1 from "../../assets/letter-bg-art-1.png"
import LetterBgArt2 from "../../assets/letter-bg-art-2.png"

import "./styles.scss"

function Letter() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function MarqueeSection() {
    return (
      <Marquee>
        {Array.from({ length: 18 }).map((_, i) => {
          const index = (i % 6) + 1
          return (
            <div key={i}>
              <div className={`letter-marquee-img letter-marquee-img-${index}`} />
            </div>
          )
        })}
      </Marquee>
    )
  }

  return (
    <div className="lp-letter-main">
      <div className="lp-letter-body">
        <div className="lp-letter-title">
          <p>
            An Open Letter from <span id="the-founder">the Founder</span>
          </p>
        </div>
        <p id="lp-letter-starter">To the Torum community:</p>
        <p id="lp-letter-text">
          I don’t want to really mess this up, as this whole landing page is dedicated to unveiling Torum V2, but I’ve
          managed to persuade our product design team to carve out a space for me to share my feelings about this
          journey with our community.
        </p>

        <p id="lp-letter-text">
          We’ve always aimed to craft a product that every cryptocurrency user desires. We dream of a world where all
          cryptocurrency enthusiasts can gather to share their insights, ideas, and expressions, empowering one another
          to forge friendships and build new, meaningful connections. We envisioned a world where everyone has the
          chance to carve out their own identity in the Web3 universe.
        </p>

        <p id="lp-letter-text">
          Since we rolled out the first version of Torum in 2020, we've managed to gather lovely people like you and
          form an amazing community, the likes of which we know we wouldn't find anywhere else on the internet. Many
          have come and gone, yet we are fortunate to have a few of you still participating in this journey with us.
          Together, we've shared laughter, tears, and joy, creating bonds that many of us will remember for the rest of
          our lives.
        </p>

        <p id="lp-letter-text">
          Years have passed. Many SocialFi projects have tried and failed, yet here we stand, still on the quest for the
          perfect SocialFi formula. Despite our efforts, and those of others, no one has yet succeeded in creating a
          universally loved SocialFi product. ‘Unfortunately’, this journey has made us one of the oldest SocialFi
          players in the industry—those with years of experience, yet still in search of that breakthrough.
        </p>

        <p id="lp-letter-text">
          It was September 2023 when we made the tough decision to reimagine Torum. Letting go of all the work we had
          developed for you and starting from scratch was a heart-wrenching decision, driven by our hope to someday
          bring to life a SocialFi platform that everyone in the Web3 universe would cherish. This time, we’ve poured
          every lesson learned, every mistake made, and all our knowledge gained over the years into Torum V2.
        </p>

        <p id="lp-letter-text">
          To our OG users who've stuck with us through Torum V1 since 2020, I extend my heartfelt thanks. Your support
          and understanding have been indispensable to our journey. I sincerely apologize that we haven’t yet delivered
          the product you fully deserved—one that matches your passion and commitment to the Web3 world. We wouldn’t
          have come this far without you. 
        </p>

        <p id="lp-letter-text">
          Now, we invite you to step with us into this new chapter of Torum—a chapter where we all unite again to shape
          the future of SocialFi.
        </p>

        <img src={LetterBgArt1} className="lp-letter-bg-1" alt="lp-letter-bg-1" />
      </div>

      {/* <div className="lp-letter-marquee marquee-container">
        <MarqueeSection />
      </div> */}
      {/* 
      <div className="lp-letter-body">
        <p id="lp-letter-text">
          Years have passed. Many SocialFi projects have tried and failed, yet here we stand, still on the quest for the
          perfect SocialFi formula. Despite our efforts, and those of others, no one has yet succeeded in creating a
          universally loved SocialFi product. ‘Unfortunately’, this journey has made us one of the oldest SocialFi
          players in the industry—those with years of experience, yet still in search of that breakthrough.
        </p>

        <p id="lp-letter-text">
          It was September 2023 when we made the tough decision to reimagine Torum. Letting go of all the work we had
          developed for you and starting from scratch was a heart-wrenching decision, driven by our hope to someday
          bring to life a SocialFi platform that everyone in the Web3 universe would cherish. This time, we’ve poured
          every lesson learned, every mistake made, and all our knowledge gained over the years into Torum V2.
        </p>

        <p id="lp-letter-text">
          To our OG users who've stuck with us through Torum V1 since 2020, I extend my heartfelt thanks. Your support
          and understanding have been indispensable to our journey. I sincerely apologize that we haven’t yet delivered
          the product you fully deserved—one that matches your passion and commitment to the Web3 world. We wouldn’t
          have come this far without you. 
        </p>

        <p id="lp-letter-text">
          Now, we invite you to step with us into this new chapter of Torum—a chapter where we all unite again to shape
          the future of SocialFi.
        </p>
      </div> */}

      <div className="lp-letter-closing">
        <p id="lp-letter-closing-name">CS Go</p>
        <p id="lp-letter-closing-sincere">Founder of Torum, on behalf of the team.</p>
      </div>

      <img src={LetterBgArt2} className="lp-letter-bg-2" alt="lp-letter-bg-2" />
    </div>
  )
}

export default Letter
