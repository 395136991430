import React, { useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useAccount, useReadContract, useAccountEffect, useSignMessage, useDisconnect, useSwitchChain } from "wagmi"
import { useWeb3Modal } from "@web3modal/wagmi/react"

import "./AppHeader.scss"

// assets
import WalletProfilePic from "../../assets/wallet-profile-pic.png"
import { ReactComponent as BlastNetworkLogo } from "../../assets/svg/blast-network.svg"
import { ReactComponent as TorumTextLogo } from "../../assets/svg/torum-text-logo.svg"
import { ReactComponent as TorumLogo } from "../../assets/svg/torum-logo.svg"
import { ReactComponent as HorizontalDots } from "../../assets/svg/horizontal-dots.svg"
import { ReactComponent as HorizontalDotsCL } from "../../assets/svg/horizontal-dots-cl.svg"

import { ReactComponent as NavbarMenuIcon } from "../../assets/svg/navbar-menu-icon.svg"
import { ReactComponent as NavbarLoginIcon } from "../../assets/svg/navbar-login-icon.svg"
import { ReactComponent as NavbarCrossIcon } from "../../assets/svg/navbar-cross-icon.svg"
import { ReactComponent as NavbarJoinIcon } from "../../assets/svg/navbar-join-icon.svg"

import { ReactComponent as NavbarDashboardIcon } from "../../assets/svg/dashboard-icon.svg"
import { ReactComponent as NavbarDisconnectIcon } from "../../assets/svg/disconnect-icon.svg"

import Button from "../Button/Button"
import { shortenWalletAddress } from "../../utils/textUtils"
import { ROUTES } from "../../constants/navigation"
import { checkEligibility, registerUser, resetUser, setSignature } from "../../store/slices/userSlice"
import { getSignatureMessage } from "../../utils/signature"
import useWindowSize from "../../hooks/useWindowResize"
import {
  BLAST_CHAIN_ID,
  BLAST_SEPOLIA_CHAIN_ID,
  SEPOLIA_CHAIN_ID,
  getChainsByEnv,
  isSupportedNetwork,
  checkAndSwitchToSupportedNetwork,
} from "../../web3/config"
import { Mixpanel } from "../../utils/mixpanel"
import { LP_Navbar_JoinWaitList } from "../../utils/mixpanel/mixpanelActionTypes"

const RouteKeys = Object.keys(ROUTES)

export const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 1023px)").matches

function AppHeader(props) {
  const dispatch = useDispatch()
  const { open } = useWeb3Modal()
  const { disconnect } = useDisconnect()

  const account = useAccount()

  const { width } = useWindowSize()

  const { signMessageAsync } = useSignMessage()

  const accessToken = useSelector((state) => state.user.accessToken)
  const scenario = useSelector((state) => state.user.scenario)

  // if wallet is connected and if scenario is identified, hide the nav options
  const shouldShowNavRoutes = !(account?.address && scenario)

  const { pathname } = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  // useAccountEffect({
  //   async onConnect(data) {
  //     if (!accessToken) {
  //       const payloadForEligibilityCheck = {
  //         address: data.address?.toLowerCase(),
  //         collectionId: "",
  //       }

  //       dispatch(checkEligibility(payloadForEligibilityCheck))
  //     }
  //   },
  //   onDisconnect() {
  //     dispatch(resetUser())
  //   },
  // })

  const handleClickOutside = (e) => {
    if (!e?.target?.parentNode?.classList?.contains("app-header-connected")) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", handleClickOutside)

    return () => {
      document.body.removeEventListener("click", handleClickOutside)
    }
  }, [])

  // useEffect(() => {
  //   async function registerWithSignature() {
  //     try {
  //       if (account.isConnected) {
  //         const signature = await signMessageAsync({ message: getSignatureMessage(account.address) })

  //         const payload = {
  //           address: account.address,
  //           signature,
  //         }

  //         dispatch(registerUser(payload))
  //       } else {
  //         dispatch(resetUser())
  //       }
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }

  //   registerWithSignature()
  // }, [account.isConnected])

  async function onClaimNFT() {
    await open({ view: "Networks" })

    // what if account is switched after wallet connection ? re-register with that new account address ?
  }

  function onWalletAddressClick() {
    // open()
    setIsOpen(!isOpen)
  }

  function onDisconnect() {
    disconnect()
    setIsOpen(false)
  }
  // function getStyle({ isActive, isPending }) {
  //   return {
  //     fontWeight: isActive ? 600 : 500,
  //   }
  // }

  // use later if needed
  function getClassName({ isActive, isPending }) {
    return isActive ? "active" : isPending ? "pending" : ""
  }

  function getConnectedNetworkName() {
    const chains = getChainsByEnv()

    if (chains[0]?.id === account?.chainId) {
      if (account?.chainId === SEPOLIA_CHAIN_ID) {
        return "Sepolia"
      }

      if (account?.chainId === BLAST_SEPOLIA_CHAIN_ID) {
        return "BlastSepolia"
      }

      if (account?.chainId === BLAST_CHAIN_ID) {
        return "Blast"
      }
    }

    return null
  }

  const [showNavbar, setShowNavbar] = useState(false)

  function toggleNavbar() {
    setShowNavbar(!showNavbar)
  }

  async function onSwitchNetwork() {
    await checkAndSwitchToSupportedNetwork()
  }

  const headerClassName = (() => {
    if (pathname === ROUTES.CREATORS_LAB.path) {
      return "app-header-sticky app-header creators-lab-special"
    }
    if (pathname === ROUTES.HOME.path) {
      return "app-header-sticky app-header "
    }
    return "app-header-sticky app-header "
  })()

  return (
    <>
      {isMobile() ? (
        <>
          <header className={headerClassName}>
            <div className="app-header-nav">
              <NavLink to={ROUTES.HOME.path} className="app-header-nav-logo">
                {
                  // Show <TorumTextLogo /> when width is above 389px and not connected & connected
                  width > 389 && !account.isConnected && account.isConnected ? (
                    <TorumTextLogo />
                  ) : // Show <TorumLogo /> when width is below 300px and not connected
                  width < 300 && !account.isConnected ? (
                    <TorumLogo />
                  ) : // If width is above 389 & user is connected, show <TorumTextLogo />
                  width < 389 && account.isConnected ? (
                    <TorumLogo />
                  ) : (
                    // Else show <TorumTextLogo /> when user is connected & above 389px width
                    <TorumTextLogo />
                  )
                }
              </NavLink>

              <div className="app-header-nav-mobile-wrapper">
                <div className="disable">
                  {account.isConnected ? (
                    <div className="app-header-connected-container">
                      {isSupportedNetwork(account?.chainId) === false ? (
                        <div className="app-header-switch-network" onClick={onSwitchNetwork}>
                          <p>Switch Network</p>
                        </div>
                      ) : null}

                      {isSupportedNetwork(account?.chainId) ? (
                        <div className="app-header-connected-network">
                          <BlastNetworkLogo />
                          {width > 560 ? (
                            <p className="app-header-connected-network-name">{getConnectedNetworkName()}</p>
                          ) : null}
                        </div>
                      ) : null}

                      <div className="app-header-connected" onClick={onWalletAddressClick}>
                        {/* account.connector.icon can be used here as well */}
                        <img src={WalletProfilePic} alt="wallet-profile-dummy" />
                        {width > 560 ? (
                          <p className="app-header-connected-address">{shortenWalletAddress(account.address)}</p>
                        ) : null}

                        {pathname === ROUTES.CREATORS_LAB.path ? <HorizontalDotsCL /> : <HorizontalDots />}

                        <ul className={`app-header-dropdown ${isOpen ? "open" : null}`}>
                          <li className="app-header-dropdown-item">
                            <NavbarDashboardIcon />
                            <NavLink to={ROUTES.DASHBOARD.path}>{ROUTES.DASHBOARD.label}</NavLink>
                          </li>
                          <li className="app-header-dropdown-item" onClick={onDisconnect}>
                            <NavbarDisconnectIcon />
                            <p className="text-red">Disconnect</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Button onClick={onClaimNFT}>
                        <NavbarLoginIcon />
                      </Button>
                    </>
                  )}
                </div>

                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    window.open("https://bsu5ua93efj.typeform.com/to/CqTXTybp", "_blank")
                  }}
                >
                  <NavbarJoinIcon />
                </Button>

                <Button onClick={toggleNavbar}>
                  <NavbarMenuIcon />
                </Button>
              </div>
            </div>
          </header>

          <div className={`app-header-mobile-nav ${showNavbar ? "show" : ""}`} onClick={toggleNavbar}>
            <ul className="app-header-nav-list">
              {RouteKeys.map((routeKey, index) => {
                if (routeKey === "LETTER" || routeKey === "DASHBOARD") {
                  return null
                }

                return (
                  <li key={index}>
                    <NavLink
                      to={ROUTES[routeKey].path}
                      onClick={() => {
                        toggleNavbar()
                        Mixpanel.track(`${routeKey}`)
                      }}
                      // style={getStyle}
                      // className={getClassName}
                    >
                      <div className="mobile-nav-wrapper">
                        {ROUTES[routeKey].label}

                        {pathname === ROUTES[routeKey].path
                          ? ROUTES[routeKey].activeIcon
                          : ROUTES[routeKey].inactiveIcon}
                      </div>
                    </NavLink>
                  </li>
                )
              })}
            </ul>

            {/* <Button onClick={toggleNavbar} className="app-header-mobile-nav-button">
              <NavbarCrossIcon />
            </Button> */}
          </div>
          <div className={`app-header-overlay ${showNavbar ? "show" : ""}`} onClick={toggleNavbar}></div>
        </>
      ) : (
        <header className={headerClassName}>
          {account.isConnected ? (
            <nav className="app-header-nav">
              <div className="app-header-nav-wrapper">
                <NavLink to={ROUTES.HOME.path} className="app-header-nav-logo">
                  <TorumTextLogo />
                </NavLink>

                <ul className="app-header-nav-list">
                  {RouteKeys.map((routeKey, index) => {
                    if (ROUTES[routeKey].isAuthenticated && !account.isConnected) {
                      return null
                    }

                    if (routeKey === "LETTER" || routeKey === "DASHBOARD") {
                      return null
                    }

                    return (
                      <li className="app-header-nav-list-box" key={index}>
                        <NavLink
                          to={ROUTES[routeKey].path}
                          onClick={() => {
                            Mixpanel.track(`${ROUTES[routeKey].mixpanelEventName}`)
                          }}
                          // style={getStyle}
                          // className={getClassName}
                        >
                          {ROUTES[routeKey].label}
                        </NavLink>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div className="disable">
                {account.isConnected ? (
                  <div className="app-header-connected-container">
                    {isSupportedNetwork(account?.chainId) === false ? (
                      <div className="app-header-switch-network" onClick={onSwitchNetwork}>
                        <p>Switch Network</p>
                      </div>
                    ) : null}

                    {isSupportedNetwork(account?.chainId) ? (
                      <div className="app-header-connected-network">
                        <BlastNetworkLogo />
                        <p className="app-header-connected-network-name">{getConnectedNetworkName()}</p>
                      </div>
                    ) : null}

                    <div className="app-header-connected" onClick={onWalletAddressClick}>
                      {/* account.connector.icon can be used here as well */}
                      <img src={WalletProfilePic} alt="wallet-profile-dummy" />

                      {width >= 430 && (
                        <p className="app-header-connected-address">{shortenWalletAddress(account.address)}</p>
                      )}

                      {pathname === ROUTES.CREATORS_LAB.path ? <HorizontalDotsCL /> : <HorizontalDots />}

                      <ul className={`app-header-dropdown ${isOpen ? "open" : null}`}>
                        <li className="app-header-dropdown-item">
                          <NavbarDashboardIcon />
                          <NavLink to={ROUTES.DASHBOARD.path}>{ROUTES.DASHBOARD.label}</NavLink>
                        </li>
                        <li className="app-header-dropdown-item" onClick={onDisconnect}>
                          <NavbarDisconnectIcon />
                          <p className="text-red">Disconnect</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <Button className="button button-primary" onClick={onClaimNFT}>
                    Connect
                  </Button>
                )}
              </div>
            </nav>
          ) : (
            <nav className="app-header-nav">
              <NavLink to={ROUTES.HOME.path} className="app-header-nav-logo">
                <TorumTextLogo />
              </NavLink>

              <ul className="app-header-nav-list">
                {RouteKeys.map((routeKey, index) => {
                  if (ROUTES[routeKey].isAuthenticated && !account.isConnected) {
                    return null
                  }

                  if (routeKey === "LETTER" || routeKey === "DASHBOARD") {
                    return null
                  }

                  return (
                    <li className="app-header-nav-list-box" key={index}>
                      <NavLink
                        to={ROUTES[routeKey].path}
                        onClick={() => {
                          Mixpanel.track(`${ROUTES[routeKey].mixpanelEventName}`)
                        }}
                        // style={getStyle}
                        // className={getClassName}
                      >
                        {ROUTES[routeKey].label}
                      </NavLink>
                    </li>
                  )
                })}
              </ul>

              <div className="disable">
                {account.isConnected ? (
                  <div className="app-header-connected-container">
                    {isSupportedNetwork(account?.chainId) === false ? (
                      <div className="app-header-switch-network" onClick={onSwitchNetwork}>
                        <p>Switch Network</p>
                      </div>
                    ) : null}

                    {isSupportedNetwork(account?.chainId) ? (
                      <div className="app-header-connected-network">
                        <BlastNetworkLogo />
                        <p className="app-header-connected-network-name">{getConnectedNetworkName()}</p>
                      </div>
                    ) : null}

                    <div className="app-header-connected" onClick={onWalletAddressClick}>
                      {/* account.connector.icon can be used here as well */}
                      <img src={WalletProfilePic} alt="wallet-profile-dummy" />

                      {width >= 430 && (
                        <p className="app-header-connected-address">{shortenWalletAddress(account.address)}</p>
                      )}

                      {pathname === ROUTES.CREATORS_LAB.path ? <HorizontalDotsCL /> : <HorizontalDots />}

                      <ul className={`app-header-dropdown ${isOpen ? "open" : null}`}>
                        <li className="app-header-dropdown-item">
                          <NavbarDashboardIcon />
                          <NavLink to={ROUTES.DASHBOARD.path}>{ROUTES.DASHBOARD.label}</NavLink>
                        </li>
                        <li className="app-header-dropdown-item" onClick={onDisconnect}>
                          <NavbarDisconnectIcon />
                          <p className="text-red">Disconnect</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <Button className="button button-primary" onClick={onClaimNFT}>
                    Connect
                  </Button>
                )}
              </div>
              <Button
                className="button button-primary"
                onClick={(e) => {
                  e.preventDefault()
                  window.open("https://bsu5ua93efj.typeform.com/to/CqTXTybp", "_blank")
                }}
              >
                Join Waitlist
              </Button>
            </nav>
          )}
        </header>
      )}
    </>
  )
  // return <>{props.children}</>
}

export default AppHeader
