export const LP_Navbar_TorumIcon = "LP_Navbar_TorumIcon"
export const HOME = "LP_Navbar_Home"
export const NFT_COLLECTIONS = "LP_Navbar_NFTCollection"
export const CREATORS_LAB = "LP_Navbar_CreatorsLab"
export const XTM = "LP_Navbar_XTM"
export const TEAM = "LP_Navbar_Team"
export const LP_Navbar_JoinWaitList = "LP_Navbar_JoinWaitList"
export const LP_Home_Whitepaper = "LP_Home_Whitepaper"
export const LP_Home_DigitalBuddies = "LP_Home_DigitalBuddies"
export const LP_Home_Tales = "LP_Home_Tales"
export const LP_Home_DigitalIdentity = "LP_Home_DigitalIdentity"
export const LP_Home_JPEG = "LP_Home_JPEG"
export const LP_Home_FounderLetter = "LP_Home_FounderLetter"
export const LP_Home_FAQ1 = "LP_Home_FAQ1"
export const LP_Home_FAQ2 = "LP_Home_FAQ2"
export const LP_Home_FAQ3 = "LP_Home_FAQ3"
export const LP_Home_FAQ4 = "LP_Home_FAQ4"
export const LP_Home_FAQ3_Here = "LP_Home_FAQ3_Here"
export const LP_Home_FAQ3_Form = "LP_Home_FAQ3_Form"
export const LP_Home_JoinWaitlist = "LP_Home_JoinWaitlist"
export const LP_NFTCollection_CoolCat = "LP_NFTCollection_CoolCat"
export const LP_NFTCollection_PudgyPenguin = "LP_NFTCollection_PudgyPenguin"
export const LP_NFTCollection_SappySeal = "LP_NFTCollection_SappySeal"
export const LP_NFTCollection_Meebit = "LP_NFTCollection_Meebit"
export const LP_NFTCollection_Moonbird = "LP_NFTCollection_Moonbird"
export const LP_NFTCollection_JoinWaitlist = "LP_NFTCollection_JoinWaitlist"
export const LP_CreatorsLab_GetInTouch1 = "LP_CreatorsLab_GetInTouch1"
export const LP_CreatorsLab_GetInTouch2 = "LP_CreatorsLab_GetInTouch2"
export const LP_XTM_LearnMore = "LP_XTM_LearnMore"
export const LP_XTM_KuCoin = "LP_XTM_KuCoin"
export const LP_XTM_HTX = "LP_XTM_HTX"
export const LP_XTM_MEXC = "LP_XTM_MEXC"
export const LP_XTM_PancakeSwap = "LP_XTM_PancakeSwap"
export const LP_Footer_TwitterIcon = "LP_Footer_TwitterIcon"
export const LP_Footer_TelegramIcon = "LP_Footer_TelegramIcon"
export const twitter = "LP_Visit_Twitter"
export const telegram = "LP_Visit_Telegram"
export const torum = "LP_Visit_Torum"
export const pm = "LP_Visit_Pm"
export const other_source = "LP_Visit_Other"
