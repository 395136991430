// const { BASE_API_URL } = process.env

const BASE_API_URL = "https://nft.api.v2.torum.team/api/v1/nftv2"

/**
 * Note: Before minting, the nft metadata is read from here.
 * After minting, the base url is read from contract
 */
const BASE_CDN_URL = 'https://assets.torum.team'

export const Endpoints = {
  REGISTER: `${BASE_API_URL}/users/registered`,
  CHECK_ELIGIBILITY: (address) => `${BASE_API_URL}/users/${address}/eligibility`,
  ROLL: (address) => `${BASE_API_URL}/users/${address}/roll`,
  ROLL_FINALIZE: `${BASE_API_URL}/users/rollFinalize`,
  GET_LOCKED_NFTS: (address) => `${BASE_API_URL}/users/${address}/fetchLockedTraits`,
  GET_NFT_METADATA_BY_TRAIT_ID: (traitId) => `${BASE_CDN_URL}/nftv2/traits/metadata/${traitId}.json`,
}
