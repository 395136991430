import React from "react"

import Marquee from "react-fast-marquee"

import Video1 from "../../../assets/marquee-mock-1.gif"
import Video2 from "../../../assets/marquee-mock-2.gif"
import Video3 from "../../../assets/marquee-mock-3.gif"
import Video4 from "../../../assets/marquee-mock-4.gif"
import Video5 from "../../../assets/marquee-mock-5.gif"
import Video6 from "../../../assets/marquee-mock-6.gif"
import Video7 from "../../../assets/marquee-mock-7.gif"
import Video8 from "../../../assets/marquee-mock-8.gif"
import Video9 from "../../../assets/marquee-mock-9.gif"
import Video10 from "../../../assets/marquee-mock-10.gif"
import Video11 from "../../../assets/marquee-mock-11.gif"
import Video12 from "../../../assets/marquee-mock-12.gif"
import Video13 from "../../../assets/marquee-mock-13.gif"
import Video14 from "../../../assets/marquee-mock-14.gif"
import Video15 from "../../../assets/marquee-mock-15.gif"
import Video16 from "../../../assets/marquee-mock-16.gif"
import Video17 from "../../../assets/marquee-mock-17.gif"
import Video18 from "../../../assets/marquee-mock-18.gif"
import Video19 from "../../../assets/marquee-mock-19.gif"
import Video20 from "../../../assets/marquee-mock-20.gif"

import "./styles.scss"

function MarqueeSection() {
  return (
    <section className="lp-section lp-section-full-width marquee-container">
      <Marquee>
        {Array.from({ length: 3 }).map((_, i) => {
          return (
            <div key={i}>
              <img src={Video1} className="marquee-img" alt="marque-img" />
              <img src={Video6} className="marquee-img" alt="marque-img" />
              <img src={Video3} className="marquee-img" alt="marque-img" />
              <img src={Video14} className="marquee-img" alt="marque-img" />
              <img src={Video12} className="marquee-img" alt="marque-img" />
              <img src={Video2} className="marquee-img" alt="marque-img" />
              <img src={Video19} className="marquee-img" alt="marque-img" />
              <img src={Video8} className="marquee-img" alt="marque-img" />
              <img src={Video18} className="marquee-img" alt="marque-img" />
              <img src={Video17} className="marquee-img" alt="marque-img" />
            </div>
          )
        })}
      </Marquee>

      <Marquee direction="right">
        {Array.from({ length: 3 }).map((_, i) => {
          return (
            <div key={i}>
              <img src={Video11} className="marquee-img" alt="marque-img" />
              <img src={Video5} className="marquee-img" alt="marque-img" />
              <img src={Video13} className="marquee-img" alt="marque-img" />
              <img src={Video4} className="marquee-img" alt="marque-img" />
              <img src={Video15} className="marquee-img" alt="marque-img" />
              <img src={Video16} className="marquee-img" alt="marque-img" />
              <img src={Video10} className="marquee-img" alt="marque-img" />
              <img src={Video9} className="marquee-img" alt="marque-img" />
              <img src={Video7} className="marquee-img" alt="marque-img" />
              <img src={Video20} className="marquee-img" alt="marque-img" />
            </div>
          )
        })}
      </Marquee>
    </section>
  )
}

export default MarqueeSection
