import ChubbyCubCollectionImage from "../assets/chubby_cub_collection.png"

export const COLLECTIONS = [
  {
    name: "Pudgy Penguins",
    url: "https://opensea.io/collection/pudgypenguins",
    image:
      "https://i.seadn.io/gae/yNi-XdGxsgQCPpqSio4o31ygAV6wURdIdInWRcFIl46UjUQ1eV7BEndGe8L661OoG-clRi7EgInLX4LPu9Jfw4fq0bnVYHqg7RFi?auto=format&dpr=1&w=64",
    collectionName: "pudgypenguins",
    mixpanelEventName: "LP_NFTCollection_PudgyPenguin",
    channelId: "65e55db1ab794ace7b522c5b",
  },
  // {
  //   name: "Chubby Cubs",
  //   // url: "https://opensea.io/collection/pudgypenguins",
  //   image: ChubbyCubCollectionImage,
  //   collectionName: "ChubbyCubs",
  //   mixpanelEventName: "LP_NFTCollection_ChubbyCub",
  //   channelId: "664493faa11aa5dab5886203",
  // },
  {
    name: "Cool Cats",
    url: "https://opensea.io/collection/cool-cats-nft",
    image:
      "https://i.seadn.io/gae/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8?auto=format&dpr=1&w=64",
    collectionName: "cool-cats-nft",
    mixpanelEventName: "LP_NFTCollection_CoolCat",
    channelId: "65e6c682088ef1d56523b74c",
  },

  {
    name: "Sappy Seals",
    url: "https://opensea.io/collection/sappy-seals",
    image: "https://i.seadn.io/gcs/files/11570389cac190891fea96fe285cbf01.png?auto=format&dpr=1&w=64",
    collectionName: "sappy-seals",
    mixpanelEventName: "LP_NFTCollection_SappySeal",
    channelId: "65e55db1ab794ace7b522c67",
  },
  {
    name: "Meebits",
    url: "https://opensea.io/collection/meebits",
    image: "https://i.seadn.io/gcs/files/2d036c8c2bed042a1588622c3173677f.png?auto=format&dpr=1&w=64",
    collectionName: "meebits",
    mixpanelEventName: "LP_NFTCollection_Meebit",
    channelId: "65e55db1ab794ace7b522c6a",
  },
  {
    name: "Moonbirds",
    url: "https://opensea.io/collection/proof-moonbirds",
    image: "https://i.seadn.io/gcs/files/4bce6187fea476154b311dafaf327c89.png?auto=format&dpr=1&w=64",
    collectionName: "proof-moonbirds",
    mixpanelEventName: "LP_NFTCollection_Moonbird",
    channelId: "65e55db1ab794ace7b522c68",
  },
]
