export const Operations = [
  {
    name: "Nicholas",
    position: "Product Strategy Lead",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "ZM",
    position: "Community Lead",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
]
