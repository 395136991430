import React from "react"
import { ReactComponent as ChevronRightIcon } from "../../../assets/svg/chevron-right-icon.svg"
import NewsMock1 from "../../../assets/news-mock-1.png"
import NewsMock2 from "../../../assets/news-mock-2.png"
import NewsMock3 from "../../../assets/news-mock-3.png"
import NewsMock4 from "../../../assets/news-mock-4.png"

import "./styles.scss"

function NewsCard({ title, createdAt, imageUrl, url }) {
  function handleOnClick() {
    // todo; redirect
  }

  return (
    <div className="news-card" onClick={handleOnClick}>
      <div className="news-card-header">
        <img src={imageUrl} />
      </div>
      <div className="news-card-body">
        <p className="news-card-timestamp text-secondary">{createdAt}</p>
        <h1 className="news-card-title">{title}</h1>
      </div>
    </div>
  )
}

function News() {
  return (
    <section className="lp-section news-section">
      <div className="lp-section-header">
        <h1 className="lp-heading">Keep up with our news!</h1>
        <p className="text">Connect with the minds of our developers and communities for inside perspectives.</p>
      </div>

      <div className="news-card-wrapper">
        <NewsCard
          title="Behind the Scenes of Your Digital Identity Creation"
          createdAt="October 9, 2024"
          imageUrl={NewsMock1}
          url={null}
        />
        <NewsCard
          title="How did AR NFTs Happen on Torum V2?"
          createdAt="September 22, 2024"
          imageUrl={NewsMock2}
          url={null}
        />
        <NewsCard
          title="A Peek at AI-generated Content, is it the Future?"
          createdAt="August 31, 2024"
          imageUrl={NewsMock3}
          url={null}
        />
        <NewsCard
          title="Behind the Scenes of Your Digital Identity Creation ins..."
          createdAt="October 9, 2024"
          imageUrl={NewsMock4}
          url={null}
        />
      </div>

      <div className="news-section-footer">
        <p className="text text-align-center">
          Want more articles?{" "}
          <a className="lp-link" href="">
            Read them here <ChevronRightIcon />
          </a>
        </p>
      </div>
    </section>
  )
}

export default News
