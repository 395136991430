import axios from "axios"

import { STATUS_CODES, ToastMessages } from "../constants"
import toast from "../global/Toast/Toast"
// import { store } from "../store/storeConfig"

const xkey = process.env.REACT_APP_X

const authUrls = ["auth/login", "auth/register"]

const axiosInstance = axios.create({})

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken")

    if (accessToken && !authUrls.includes(config.url)) {
      config.headers.Authorization = `Bearer ${accessToken}`
    } else {
      delete config.headers.Authorization
    }

    config.method === "get" && delete config.data

    return config
  },
  (error) => Promise.reject(error)
)

async function responseErrorCallback(error) {
  const { status, data } = error.response || {}

  // Handle 500, 502, 503 error
  if (
    [
      STATUS_CODES.NOT_FOUND,
      STATUS_CODES.INTERNAL_SERVER_ERROR, // 500
      STATUS_CODES.BAD_GATEWAY, // 502
      STATUS_CODES.SERVICE_UNAVAILABLE, // 503
    ].includes(status)
  ) {
    toast({
      type: "error",
      text: "Something went wrong. Please try again later!",
    })
    return Promise.reject(data)
  }

  // Handle expired token error
  // if (status === STATUS_CODES.UNAUTHORIZED && data.message?.name === 'TokenExpiredError') {
  //   return refreshTokenHandler(error);
  // }

  if (status === STATUS_CODES.UNAUTHORIZED) {
    // toast({
    //   type: "error",
    //   text: "Session expired. Please login again.",
    // })

    // TODO: Add logout dispatch when needed
    // store.dispatch(logout());
    return Promise.reject(data)
  }

  return Promise.reject(data)
}

axiosInstance.interceptors.response.use((response) => response, responseErrorCallback)

/**
 * Handles API calls using Axios.
 * @param {String} url - The API endpoint URL.
 * @param {String} [method='POST'] - The HTTP method.
 * @param {Object} data - The request payload data.
 * @param {Object} params - The query parameters.
 * @param {Object} abortControllerRef - The AbortController reference.
 * @returns {Promise} - A Promise containing the Axios response.
 */
export const apiCaller = async (url, method = "POST", data, params, abortControllerRef) => {
  return axiosInstance({
    url,
    method,
    data,
    params,
  })
}
