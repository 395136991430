import { Slide, toast as ReactToast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "./Toast.scss"

const config = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "light",
  transition: Slide,
}

function toast({ text = "", customConfig = {} }) {
  ReactToast(text, {
    ...config,
    ...customConfig,
  })
}

export default toast
