import React from "react"

import "./Card.scss"

function Card({ title, description, icon: Icon }) {
  return (
    <div className="card">
      <div className="card-header">
        <Icon className="card-header-icon" />
      </div>
      <div className="card-body">
        <h1 className="card-title">{title}</h1>
        <p className="card-description">{description}</p>
      </div>
    </div>
  )
}

function CardWrapper({ children }) {
  return <div className="card-wrapper">{children}</div>
}

export { Card, CardWrapper }
