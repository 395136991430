import { Operations } from "./op-list"

import OperationsNft from "../../../assets/operation-nft.png"

import "./styles.scss"

function OperationsCard() {
  return (
    <div className="wrapper op-wrapper">
      {Operations.map((member) => {
        return (
          <div className="team-members-card">
            <img src={OperationsNft} className="member-pfp" alt="member-pfp" />
            <div className="member-detail-wrapper">
              <div className="member-name">{member.name}</div>
              <div className="member-position">{member.position}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
export default OperationsCard
