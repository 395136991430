export const Tech = [
  {
    name: "Pardeep",
    position: "Front-End Lead, Project Manager",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "Nagendra",
    position: "Back-End Lead",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "Vishal",
    position: "DevOps Engineer",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "Karthik",
    position: "QA Lead",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
]
