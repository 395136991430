import React from "react"

import TorumTextLogo from "../../assets/svg/torum-text-logo.svg"
import TwitterIcon from "../../assets/svg/x-twitter-icon.svg"
import TelegramIcon from "../../assets/svg/telegram-icon.svg"
import FooterBg from "../../assets/footer-bg.png"

import { ROUTES } from "../../constants/navigation"
import { NavLink, useLocation } from "react-router-dom"

import "./AppFooter.scss"
import { Mixpanel } from "../../utils/mixpanel"
import {
  LP_Footer_TelegramIcon,
  LP_Footer_TwitterIcon
} from "../../utils/mixpanel/mixpanelActionTypes"

function AppFooter(props) {
  const { pathname } = useLocation()

  const footerClassName = (() => {
    if (pathname === ROUTES.CREATORS_LAB.path) {
      return "lp-dark-bg-customize"
    }

    return null
  })()

  const CurrentYear = () => {
    const currentYear = new Date().getFullYear()

    return currentYear
  }

  return (
    <footer className={footerClassName}>
      <img src={FooterBg} className="app-footer-bg" alt="app-footer-bg" />

      <div className="app-footer-content">
        <div className="app-footer-top">
          <div className="app-footer-logo-wrapper">
            <img src={TorumTextLogo} className="TorumTextLogo" alt="TorumTextLogo" />

            <div className="app-footer-icon-wrapper">
              <a href="https://x.com/torum_official" target="_blank" onClick={()=>{Mixpanel.track(LP_Footer_TwitterIcon)}}>
                <img src={TwitterIcon} alt="lp-social-twitter" />
              </a>
              <a href="https://t.me/torum_official_group_en" target="_blank" onClick={()=>{Mixpanel.track(LP_Footer_TelegramIcon)}}>
                <img src={TelegramIcon} alt="lp-social-telegram" />
              </a>
            </div>
          </div>

          {/* <div className="app-footer-link-wrapper">
            <a href="#" className="footer-button">
              Branding Kit
            </a>
            <a href="#" className="footer-button">
              Gitbook
            </a>{" "}
            <a href="#" className="footer-button">
              FAQ
            </a>
            <a href="#" className="footer-button">
              Help Center
            </a>
            <a href="#" className="footer-button">
              Whitepaper
            </a>
            <a href="#" className="footer-button">
              Team
            </a>
          </div> */}
        </div>
        <div className="app-copyright-support-wrapper">
          <p className="app-footer-copyright">
            Contact us at <span>support@torum.com</span>
          </p>
          <p className="app-footer-copyright">
            © 2018 - <CurrentYear /> Torum Technology Sdn. Bhd. All rights reserved.
          </p>
        </div>
      </div>

      {/* <div className="app-footer">
        <div className="app-footer-wrapper">
          <div className="app-footer-logo-wrapper">
            <TorumTextLogo />

            <div className="app-footer-icon-wrapper">
              <DiscordIcon />
              <TwitterIcon />
              <TelegramIcon />
            </div>
          </div>

          <div className="app-footer-link-wrapper">
            <ul>
              <li>
                <a href="#">Branding Kit</a>
              </li>
              <li>
                <a href="#">Whitepaper</a>
              </li>
              <li>
                <a href="#">Gitbook</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="#">Help Center</a>
              </li>
              <li>
                <a href="#">FAQ</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="#">Team</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="app-footer-copyright">
          <p className="text text-secondary">© 2018-2024 Torum Technology Sdn. Bhd. All rights reserved.</p>
        </div>
      </div>
      <img src={FooterBg} className="app-footer-bg" alt="app-footer-bg" /> */}
    </footer>
  )
}

export default AppFooter
