import React from "react"

import AppHeader from "../AppHeader/AppHeader"
import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import AppFooter from "../AppFooter/AppFooter"

// AppLayout moved to a separate file to fix circular dependency issue
// https://github.com/reduxjs/redux-toolkit/issues/167
const AppLayout = () => {
  return (
    <>
      {/* Is this AppHeader component the reason behind 'cant access Store' error ?  */}
      <AppHeader />
      <main className="main">
        <Outlet /> {/* Outlet is where the child routes will be rendered */}
        <ToastContainer className="my-toast-container" /> {/* renders Toast component */}
      </main>
      <AppFooter />
    </>
  )
}

export default AppLayout
