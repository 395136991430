import { Founders } from "./founder-list"

import FounderNft from "../../../assets/founder-nft.png"

import "./styles.scss"

function FounderCard() {
  return (
    <div className="wrapper">
      {Founders.map((member) => {
        return (
          <div className="team-members-card">
            <img src={FounderNft} className="member-pfp" alt="member-pfp" />
            <div className="member-detail-wrapper">
              <div className="member-name">{member.name}</div>
              <div className="member-position">{member.position}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default FounderCard
