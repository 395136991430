export const Creative = [
  {
    name: "WJ",
    position: "Creative Lead",
    userId: "wenjie",
    // imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "WT",
    position: "Digital Designer",
    userId: "wt",
    // imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "Oscar",
    position: "3D Artist",
    userId: "oscar",
    // imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "Shiang",
    position: "UI/UX",
    userId: "shiang",
    // imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
]
