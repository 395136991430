import axios from "axios"
import QRCode from "qrcode"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { Endpoints } from "../../axios/endpoints"
import { apiCaller } from "../../axios/client"
import { getMetadataBaseURIFromContract, getOwnedTokenIdListFromContract } from "../../utils/getContractData"

export const NFT_TRAITS_STATUS = {
  AVAILABLE: 0, // NFT is available to show to the user
  TIME_LOCKED: 1, // user has received the NFT list and 10 min countdown is running
  USER_LOCKED: 2, // user selected NFT but not yet minted
  ASSIGNED: 3, // user minted NFT and owns it
}

export const SCENARIO_TYPE = {
  AVATAR_WHITELIST: "avatar-whitelist",
  LEGACY_WHITELIST: "legacy-whitelist",
  COMMUNITY_NFT_HOLDER: "community-nft-holder",
  ELIGIBLE_FOR_FILTER_ONLY: "eligible-for-filter-only",
  PUBLIC: "public",
}

export const AVATAR_SCENARIO_STEPS = {
  START_ROLLING: "start-rolling", // shows 'start rolling' text on Roll button
  RAFFLE_RULES: "raffle-rules", // shows raffle rules modal
  RAFFLE_OPTIONS: "raffle-options", // shows roll all button
  RAFFLE_ONGOING: "raffle-ongoing",
  RAFFLE_SUCCESS: "raffle-success",
  NFT_DETAILS: "nft-details", // ? this is a modal. can we skip this variable ?
  COUNTDOWN_TIMEOUT: "countdown-timeout",
  MINT_ONGOING: "mint-ongoing",
  MINT_REJECTED: "mint-rejected",
  MINT_SUCCESS: "mint-success",
}

export const LEGACY_SCENARIO_STEPS = {
  MINT_DECISION: "mint-decision",
  MINT_ONGOING: "mint-ongoing",
  MINT_REJECTED: "mint-rejected",
  MINT_SUCCESS: "mint-success",
}

export const PUBLIC_SCENARIO_STEPS = {
  MINT_DECISION: "mint-decision",
  MINT_ONGOING: "mint-ongoing",
  MINT_REJECTED: "mint-rejected",
  MINT_SUCCESS: "mint-success",
}

export const ELIGIBLE_FOR_FILTER_ONLY_SCENARIO_STEPS = {
  // no minting happens in this scenario.
  // I'm reusing the steps to avoid more conditions just for this scenario
  MINT_DECISION: "mint-decision",
  // MINT_ONGOING: "mint-ongoing",
  // MINT_REJECTED: "mint-rejected",
  MINT_SUCCESS: "mint-success",
}

function getNFTLockType(lockedNFTs) {
  if (lockedNFTs?.length === 1) {
    if (lockedNFTs[0].status === NFT_TRAITS_STATUS.TIME_LOCKED) {
      return "time-locked"
    }
    if (lockedNFTs[0].status === NFT_TRAITS_STATUS.USER_LOCKED) {
      return "user-locked"
    }
  }
  return ""
}

// possible userType are NFT_Holder - 0, Whitelist - 1, Public - 2
export function getScenario(eligibility = {}) {
  // nft holder ( either avatar nft or community nft or both )
  if (eligibility.userType === 0) {
    if (eligibility.isEligibleforRaffle) {
      return SCENARIO_TYPE.AVATAR_WHITELIST
    } else if (eligibility.isEligibleforFreeMint) {
      if (eligibility.NFTs?.length > 0) {
        return SCENARIO_TYPE.COMMUNITY_NFT_HOLDER
      } else {
        // after roll, during timer countdown, if user refreshes, the isEligibleForRaffle comes false this time
        return SCENARIO_TYPE.AVATAR_WHITELIST
      }
    }

    if (eligibility.isEligibleforRaffle === false && eligibility.isEligibleforFreeMint === false) {
      if (eligibility.NFTs?.length > 0) {
        return SCENARIO_TYPE.ELIGIBLE_FOR_FILTER_ONLY
      }
    }
  }

  // whitelisted user legacy userType === 1
  // whitelisted user marketing userType === 3
  // intentionally did not create new SCENARIO_TYPE for marketing since there is no change in UI.
  if ([1, 3].includes(eligibility.userType)) {
    // isEligibleForRaffle will be false
    if (eligibility.isEligibleforFreeMint) {
      return SCENARIO_TYPE.LEGACY_WHITELIST
    }
  }

  // public user
  if (eligibility.userType === 2) {
    // isEligibleForRaffle will be false
    // isEligibleforFreeMint will be false
    return SCENARIO_TYPE.PUBLIC
  }
}

export const getUserData = createAsyncThunk("user/getUser", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiCaller(Endpoints.PLACEHOLDER, "GET")
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const registerUser = createAsyncThunk("user/register", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiCaller(Endpoints.REGISTER, "POST", payload)

    if (response.data.success) {
      localStorage.setItem("accessToken", response.data.data.access_token)
      localStorage.setItem("refreshToken", response.data.data.refreshToken)

      // edge case: user rolled once and is yet to decide.
      // So, either the timer runs out or user leaves the app while the timer is running
      // When they're back later, the timer would have run out and
      // we would have picked a nft for them and show time-out UI
      // At that stage, they can only mint the NFT which is picked on their behalf.
      dispatch(getLockedNFTs({ address: payload.address }))
    }
    return response.data.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const checkEligibility = createAsyncThunk("user/eligibility", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiCaller(Endpoints.CHECK_ELIGIBILITY(payload.address), "GET")

    // in Avatar flow, this is useful incase the user is time-locked
    // this is called here now since register api is called very late after new changes
    dispatch(getLockedNFTs({ address: payload.address }))

    const maxRetries = 3
    const retryDelay = 3000 // ms

    const ownedTokenIdList = await getOwnedTokenIdListFromContract(payload.address, maxRetries, retryDelay)

    // could be blue chip or chubby cub
    const hasNFTs = response.data.data?.nfts?.length > 0
    const isRegistered = response.data.data?.isRegistered
    // const userType = response.data.data?.userType

    /**
     * Note: These 4 states are used to manage UI
     *
     *
     * isEligibleToMint
     * isEligibleToClaimARFilter
     *
     * isMinted
     * isARFilterClaimed
     */
    return {
      eligibility: response.data.data, //
      isMinted: ownedTokenIdList.length > 0, //
      isEligibleToMint: ownedTokenIdList.length === 0, //
      isARFilterClaimed: hasNFTs && isRegistered,
      isEligibleToClaimARFilter: hasNFTs,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const rollAll = createAsyncThunk("user/roll-all", async (payload, { dispatch, rejectWithValue }) => {
  try {
    dispatch(changeStep(AVATAR_SCENARIO_STEPS.RAFFLE_ONGOING))

    const response = await apiCaller(Endpoints.ROLL(payload.address), "POST")

    if (response.data.success) {
      dispatch(changeStep(AVATAR_SCENARIO_STEPS.RAFFLE_SUCCESS))
      dispatch(getLockedNFTs({ address: payload.address }))
    }

    return response.data.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getLockedNFTs = createAsyncThunk(
  "user/get-locked-nfts",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiCaller(Endpoints.GET_LOCKED_NFTS(payload.address), "GET")

      return response.data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const rollFinalize = createAsyncThunk("user/roll-finalize", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiCaller(Endpoints.ROLL_FINALIZE, "POST", payload)

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

/**
 * Note: trait id is different than token id.
 * token id is the token # minted on chain. It is sequentially generated based on FCFS.
 * trait id is the id which represents the combination of features on a token ( NFT )
 * We mint a token and then assign a trait id to it.
 * For example, if a user mints token # 199, then our backend assigns some trait id to it.
 * The trait id MAY or MAY NOT be a rare set of features.
 */
export const getNFTMetadataByTraitId = createAsyncThunk(
  "user/get-nft-metadata",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch(Endpoints.GET_NFT_METADATA_BY_TRAIT_ID(payload.traitId))

      const json = await response.json()

      return {
        traitId: payload.traitId,
        ...json,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

/**
 * Expects token id ( nft id )
 *
 */
export const getNFTMetadataByTokenId = createAsyncThunk(
  "user/get-nft-metadata-by-token-id",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const baseURI = await getMetadataBaseURIFromContract()

      console.log(baseURI, "baseURI")

      let valueToAppend = null

      if (baseURI.includes("ipfs")) {
        valueToAppend = payload.tokenId
      } else {
        valueToAppend = `${payload.tokenId}.json`
      }

      const response = await fetch(baseURI + valueToAppend)

      const json = await response.json()

      return json
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createDeeplink = createAsyncThunk(
  "user/create-deeplink",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "post",
        url: "https://api2.branch.io/v1/url",
        data: payload,
      })

      const qrcode = await QRCode.toDataURL(response.data.url)

      return {
        deeplink: response.data.url,
        qrcode,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const delay = (ms = 3000) => new Promise((resolve) => setTimeout(resolve, ms))

// watches nft array length inside this api response
export const watchEligibilityApi = createAsyncThunk(
  "user/watch-eligibility",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      while (true) {
        const response = await apiCaller(Endpoints.CHECK_ELIGIBILITY(payload.address), "GET")

        if (response.data.data?.nfts?.length !== payload.prevLength) {
          return true
        }

        await delay(payload.interval)
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)


const initialState = {
  scenario: "",
  step: "", // scenario step

  mintTxHash: "",
  mintedTokenId: null,

  // registration
  accessToken: "",
  refreshToken: "",
  signature: "",
  isRegistrationOngoing: false,

  // eligibility check
  eligibility: {},
  isEligibilityCheckOngoing: false,
  isEligibleToMint: false,
  isMinted: false,
  isARFilterClaimed: false,
  isEligibleToClaimARFilter: false,

  // roll
  isRollOngoing: false,
  isRollFinalizeOngoing: false,
  isGettingLockedNFTs: false,
  lockedNFTs: [],
  lockedUntil: "",
  lockType: "",

  // nft details
  isNFTDetailsModalOpen: false,
  selectedNFT: null, // ALSO used to store nft metadata json which is rendered in final screen
  isGettingNFTMetadata: false,

  // final screen
  isCreatingDeeplink: false,
  deeplink: "",
  qrcode: "",
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.step = ""
      state.scenario = ""
      state.mintTxHash = ""
      state.mintedTokenId = null
      state.accessToken = ""
      state.refreshToken = ""
      state.signature = ""
      state.isRegistrationOngoing = false
      state.eligibility = {}
      state.isEligibilityCheckOngoing = false
      state.isEligibleToMint = false
      state.isMinted = false
      state.isARFilterClaimed = false
      state.isEligibleToClaimARFilter = false
      state.isRollOngoing = false
      state.isRollFinalizeOngoing = false
      state.isGettingLockedNFTs = false
      state.lockedNFTs = []
      state.lockedUntil = null
      state.lockType = ""
      state.isNFTDetailsModalOpen = false
      state.selectedNFT = null
      state.isGettingNFTMetadata = false
      state.isCreatingDeeplink = false
      state.deeplink = ""
      state.qrcode = ""
    },
    changeScenario: (state, action) => {
      state.scenario = action.payload
    },
    changeStep: (state, action) => {
      state.step = action.payload
    },
    setMintTxHash: (state, action) => {
      state.mintTxHash = action.payload
    },
    setSignature: (state, action) => {
      state.signature = action.payload
    },
    setNFTDetailsModalStatus: (state, action) => {
      state.isNFTDetailsModalOpen = action.payload
    },
    setSelectedNFT: (state, action) => {
      state.selectedNFT = action.payload
    },
    setMintedTokenId: (state, action) => {
      state.mintedTokenId = action.payload
    },
    resetAvatarScenarioState: (state) => {
      state.lockedNFTs = []
      state.lockedUntil = null
      state.lockType = ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, action) => {
        state.accessToken = action.payload.access_token
        state.refreshToken = action.payload.refreshToken
        state.isRegistrationOngoing = false
      })
      .addCase(registerUser.pending, (state) => {
        state.isRegistrationOngoing = true
      })
      .addCase(registerUser.rejected, (state) => {
        state.isRegistrationOngoing = false
      })
      .addCase(checkEligibility.fulfilled, (state, action) => {
        state.eligibility = action.payload.eligibility
        state.isMinted = action.payload.isMinted
        state.isEligibleToMint = action.payload.isEligibleToMint
        state.isARFilterClaimed = action.payload.isARFilterClaimed
        state.isEligibleToClaimARFilter = action.payload.isEligibleToClaimARFilter
        state.isEligibilityCheckOngoing = false
      })
      .addCase(checkEligibility.pending, (state) => {
        state.isEligibilityCheckOngoing = true
      })
      .addCase(checkEligibility.rejected, (state) => {
        state.isEligibilityCheckOngoing = false
      })
      .addCase(rollAll.fulfilled, (state, action) => {
        state.isRollOngoing = false
      })
      .addCase(rollAll.pending, (state) => {
        state.isRollOngoing = true
      })
      .addCase(rollAll.rejected, (state) => {
        state.isRollOngoing = false
      })
      .addCase(rollFinalize.fulfilled, (state, action) => {
        state.isRollFinalizeOngoing = false
      })
      .addCase(rollFinalize.pending, (state) => {
        state.isRollFinalizeOngoing = true
      })
      .addCase(rollFinalize.rejected, (state) => {
        state.isRollFinalizeOngoing = false
      })
      .addCase(getLockedNFTs.fulfilled, (state, action) => {
        state.isGettingLockedNFTs = false
        state.lockedNFTs = action.payload.formattedTraits
        state.lockedUntil = action.payload.lockedUntil
        // to keep track of the nft lock type
        if (action.payload.formattedTraits.length === 1) {
          state.lockType = getNFTLockType(action.payload.formattedTraits)
        }
      })
      .addCase(getLockedNFTs.pending, (state) => {
        state.isGettingLockedNFTs = true
      })
      .addCase(getLockedNFTs.rejected, (state) => {
        state.isGettingLockedNFTs = false
      })
      .addCase(getNFTMetadataByTraitId.fulfilled, (state, action) => {
        state.selectedNFT = action.payload
        state.isGettingNFTMetadata = false
      })
      .addCase(getNFTMetadataByTraitId.pending, (state) => {
        state.isGettingNFTMetadata = true
      })
      .addCase(getNFTMetadataByTraitId.rejected, (state) => {
        state.isGettingNFTMetadata = false
      })
      .addCase(getNFTMetadataByTokenId.fulfilled, (state, action) => {
        state.selectedNFT = action.payload
        state.isGettingNFTMetadata = false
      })
      .addCase(getNFTMetadataByTokenId.pending, (state) => {
        state.isGettingNFTMetadata = true
      })
      .addCase(getNFTMetadataByTokenId.rejected, (state) => {
        state.isGettingNFTMetadata = false
      })
      .addCase(createDeeplink.fulfilled, (state, action) => {
        state.deeplink = action.payload.url
        state.qrcode = action.payload.qrcode
        state.isCreatingDeeplink = false
      })
      .addCase(createDeeplink.pending, (state) => {
        state.isCreatingDeeplink = true
      })
      .addCase(createDeeplink.rejected, (state) => {
        state.isCreatingDeeplink = false
      })
  },
})

export const {
  changeScenario,
  changeStep,
  setMintTxHash,
  setSignature,
  setNFTDetailsModalStatus,
  setSelectedNFT,
  setMintedTokenId,
  resetUser,
  resetAvatarScenarioState,
} = userSlice.actions

export default userSlice.reducer
