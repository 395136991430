import { Navigate, BrowserRouter, Routes, Route, useLocation } from "react-router-dom"
import { Provider, useSelector } from "react-redux"

import { persistor, store } from "./store/storeConfig.js"
import { PersistGate } from "redux-persist/integration/react"
import { Web3ModalProvider, initWeb3Modal } from "./web3/config.js"

import ThemeProvider from "./context/ThemeContext.js"

// constants
import { ROUTES } from "./constants/navigation.js"

// components
import Home from "./pages/home/Home.js"
import NFTCollection from "./pages/NFTCollection"
import CreatorLabs from "./pages/CreatorLabs"
import XTM from "./pages/XTM"
import Team from "./pages/Team"
// import Dashboard from "./pages/Dashboard"
import Letter from "./pages/Letter/index.js"
import AppLayout from "./components/AppLayout/AppLayout.js"
import { useEffect } from "react"
import { Mixpanel } from "./utils/mixpanel"
import { other_source } from "./utils/mixpanel/mixpanelActionTypes"

initWeb3Modal()

function ProtectedRoute({ children }) {
  const isAuthenticated = useSelector((state) => state.user.accessToken)

  if (!isAuthenticated) {
    return <Navigate to="/" />
  }

  return children
}

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  const search = useLocation().search

  useEffect(() => {
    const utm_source = new URLSearchParams(search).get("utm_source")
    const mixpanel_event = {
      twitter_pudgypm: "LP_Visit_Twitter_PudgyPm",
      twitter_coolcatpm: "LP_Visit_Twitter_CoolCatPm",
      twitter_moonbirdpm: "LP_Visit_Twitter_MoonbirdPm",
      twitter_meebitpm: "LP_Visit_Twitter_MeebitPm",
      twitter_sappypm: "LP_Visit_Twitter_SappyPm",
      twitter: "LP_Visit_Twitter",
      telegram: "LP_Visit_Telegram",
      torum: "LP_Visit_Torum",
      pudgypm: "LP_Visit_Twitter_PudgyPm",
      coolcatpm: "LP_Visit_Twitter_CoolCatPm",
      moonbirdpm: "LP_Visit_Twitter_MoonbirdPm",
      meebitpm: "LP_Visit_Twitter_MeebitPm",
      sappypm: "LP_Visit_Twitter_SappyPm",
      coingecko: "LP_Visit_CoinGecko",
      cmc: "LP_Visit_Cmc",
      medium: "LP_Visit_Medium",
    }

    Mixpanel.track(`${utm_source ? mixpanel_event[utm_source] : other_source}`)
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <Web3ModalProvider>
            <ScrollToTop />
            <Routes>
              <Route element={<AppLayout />}>
                <Route path={ROUTES.HOME.path} element={<Home />} />
                {/* <Route path={ROUTES.BLOGS.path} element={<div>This is blogs page</div>} /> */}
                <Route path={ROUTES.NFT_COLLECTION.path} element={<NFTCollection />} />
                {/* <Route path={ROUTES.CREATORS_LAB.path} element={<CreatorLabs />} /> */}
                {/* <Route path={ROUTES.XTM.path} element={<XTM />} /> */}
                {/* <Route path={ROUTES.APP.path} element={<div>This is app page</div>} /> */}
                {/* <Route path={ROUTES.FAQ.path} element={<div>This is FAQs page</div>} /> */}
                <Route path={ROUTES.TEAM.path} element={<Team />} />
                {/* <Route
                    path={ROUTES.DASHBOARD.path}
                    element={
                      <ProtectedRoute>
                      <Dashboard />
                      </ProtectedRoute>
                    }
                  /> */}
                {/* for invalid routes, navigate to home */}
                <Route path={ROUTES.LETTER.path} element={<Letter />} />
                <Route path={"*"} element={<Navigate to={ROUTES.HOME.path} />} />
              </Route>
            </Routes>
          </Web3ModalProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
