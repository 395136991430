import React, { useEffect } from "react"
// import { getUserData } from "../../store/slices/userSlice"
// import { useDispatch } from "react-redux"

import Hero from "./Hero"
import YourNFT from "./YourNFT"
import Marquee from "./Marquee"
import GettingStarted from "./GettingStarted"
import News from "./News"
import Banner from "./Banner/Banner"
import FAQ from "./FAQ"
import CTA from "./CTA"

import "./Home.scss"

function Home() {
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   // dispatch(getUserData())
  // }, [])

  return (
    <div className="lp lp-home-container">
      <Hero />
      <YourNFT />
      <Marquee />
      {/* <GettingStarted /> Available when minting start*/}
      {/* <News />  Available when minting start*/}
      {/* <Banner /> */}
      <FAQ />
      <CTA />
    </div>
  )
}

export default Home
