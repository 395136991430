import XtmBg from "../../assets/xtm-background.png"
import MainCharacter from "../../assets/xtm-hero-main-character.png"
import SecondCharacter from "../../assets/xtm-hero-2nd-character.png"
import Cloud1 from "../../assets/cloud-1.png"
import Cloud2 from "../../assets/cloud-2.png"
import Cloud3 from "../../assets/cloud-3.png"
import Coin1 from "../../assets/coin-1.png"
import Coin2 from "../../assets/coin-2.png"

import StarIcon from "../../assets/svg/star-icon.svg"
import StarIcon1 from "../../assets/svg/star-icon-1.svg"
import StarIcon2 from "../../assets/svg/star-icon-2.svg"
import StarIcon3 from "../../assets/svg/star-icon-3.svg"
import StakingYields from "../../assets/xtm-body-img-1.png"
import CommunityGovernance from "../../assets/xtm-body-img-2.png"
import EngageReward from "../../assets/xtm-body-img-3.png"

import OxtmImg from "../../assets/xtm-body-img-4.png"
import KucoinIcon from "../../assets/get-xtm-img-kucoin.png"
import HtxIcon from "../../assets/get-xtm-img-htx.png"
import MexcIcon from "../../assets/get-xtm-img-mexc.png"
import PancakeIcon from "../../assets/get-xtm-img-pancake.png"
import OxtmIcon from "../../assets/get-xtm-img-oxtm.png"
import ArtisanIcon from "../../assets/get-xtm-img-artisan.png"

import UseXtm from "../../assets/svg/use-xtm.svg"
import NeedXtm from "../../assets/svg/need-xtm.svg"

import SubtleBg from "../../assets/svg/subtle-bg.svg"
import SubtleBgMobile from "../../assets/svg/subtle-bg-mobile.svg"
import SubtleBg960 from "../../assets/svg/subtle-bg-960.svg"

import "./styles.scss"

import { Swiper, SwiperSlide } from "swiper/react"
import useWindowSize from "../../hooks/useWindowResize"

import "swiper/css"
import "swiper/css/pagination"

import { FreeMode, Mousewheel, Pagination } from "swiper/modules"
import Button from "../../components/Button/Button"
import { useRef } from "react"
import { Mixpanel } from "../../utils/mixpanel"
import {
  LP_NFTCollection_JoinWaitlist,
  LP_XTM_HTX,
  LP_XTM_KuCoin,
  LP_XTM_LearnMore,
  LP_XTM_MEXC,
  LP_XTM_PancakeSwap,
} from "../../utils/mixpanel/mixpanelActionTypes"

function XTM() {
  const ref = useRef()
  const { width } = useWindowSize()

  return (
    <div className="lp-xtm-main">
      {width > 960 ? (
        <img src={SubtleBg} className="lp-xtm-sub-bg" alt="subtle-bg" />
      ) : width > 430 ? (
        <img src={SubtleBg960} className="lp-xtm-sub-bg" alt="subtle-bg" />
      ) : (
        <img src={SubtleBgMobile} className="lp-xtm-sub-bg" alt="subtle-bg" />
      )}

      <div className="lp-xtm-hero">
        {/* <img src={Cloud1} className="lp-xtm-hero-cloudone" alt="maincharacter" />
        <img src={Cloud2} className="lp-xtm-hero-cloudtwo" alt="maincharacter" />
        <img src={Cloud3} className="lp-xtm-hero-cloudthree" alt="maincharacter" /> */}
        <img src={Coin1} className="lp-xtm-hero-coinone" alt="maincharacter" />
        <img src={Coin2} className="lp-xtm-hero-cointwo" alt="maincharacter" />
        <img src={SecondCharacter} className="lp-xtm-hero-secondcharacter" alt="maincharacter" />
        <img src={MainCharacter} className="lp-xtm-hero-maincharacter" alt="maincharacter" />

        <div className="lp-xtm-hero-content">
          <p className="lp-xtm-hero-text">
            What is<span className="lp-xtm-hero-text-bold"> $XTM</span>?
          </p>

          <p id="lp-xtm-hero-text-description">
            $XTM is the token that powers the Torum ecosystem, created to align and incentivize all stakeholders within
            our community.
          </p>

          {/* <a href="#"> */}
          <Button
            className="lp-xtm-hero-button"
            onClick={() => {
              ref.current.scrollIntoView()
              Mixpanel.track(LP_XTM_LearnMore)
            }}
          >
            Learn More
          </Button>
          {/* </a> */}
        </div>
      </div>

      {/* BODY---------------------------------------------------------------------------------------------------------------------- */}

      <div ref={ref} className="lp-xtm-body">
        <div className="lp-xtm-body-wrapper">
          <div className="lp-xtm-hero-sub">
            Our vision is to spread the majority of $XTM ownership among our early adopters, ensuring our community
            benefits from the growing value of the token as the platform grows—<span>a win-win for everyone</span>!
          </div>
          <div className="lp-xtm-whyhow-container">
            <div className="lp-xtm-whyhow-wrapper first ">
              <img src={NeedXtm} className="lp-xtm-whyhow-icon" alt="maincharacter" />
              <p id="lp-xtm-whyhow-title">Why Do You Need It?</p>
              <p id="lp-xtm-whyhow-description">
                By being an $XTM holder, you don’t just get a token. You get a front-row seat to the future of social
                interactions, becoming an integral part of our groundbreaking movement as we push the boundaries of
                SocialFi and transform the NFT landscape.
              </p>
            </div>

            <div className="lp-xtm-whyhow-wrapper second">
              <img src={UseXtm} className="lp-xtm-whyhow-icon" alt="maincharacter" />
              <p id="lp-xtm-whyhow-title">How Can You Use $XTM? </p>
              <p id="lp-xtm-whyhow-description">
                We’re implementing several initiatives to ensure $XTM holders get to enjoy progressive ownership and
                benefits from the exponential growth of Torum’s ecosystem in the long run.
              </p>
            </div>
          </div>

          <div className="lp-xtm-usecase-container">
            <Swiper
              slidesPerView="auto"
              // slidesPerView={1}
              spaceBetween={36}
              // centeredSlides={fa}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                430: {
                  slidesPerView: 1,
                  // spaceBetween: 20,
                  centeredSlides: true,
                },
                768: {
                  slidesPerView: 2,
                  // spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  // spaceBetween: 50,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="lp-xtm-usecase-container-wrapper grid-first">
                  <img src={StakingYields} className="lp-xtm-usecase-img" alt="lp-xtm-usecase-img" />
                  <div className="lp-xtm-usecase-description-wrapper first">
                    <div className="lp-xtm-usecase-title-wrapper">
                      <img src={StarIcon1} className="lp-xtm-usecase-icon" alt="lp-xtm-usecase-icon" />
                      <p id="lp-xtm-usecase-title">Staking for More Yields</p>
                    </div>
                    <p>
                      Torum's treasury, accrued from the sales of our Chubby Cubs NFT collection, Creators Lab fees, and
                      a variety of other avenues, will be deployed to harvest yields across sustainable DeFi protocols.
                    </p>

                    <p>
                      The majority of the yields accrued will be utilized to repurchase $XTM from the open market, which
                      will then be distributed to on-chain holders who stake their $XTM. Additionally, users
                      participating in $XTM staking will automatically gain entry into NFT whitelist raffles from
                      Torum’s community of artists and creators.
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="lp-xtm-usecase-container-wrapper">
                  <img src={CommunityGovernance} className="lp-xtm-usecase-img" alt="lp-xtm-usecase-img" />
                  <div className="lp-xtm-usecase-description-wrapper second">
                    <div className="lp-xtm-usecase-title-wrapper">
                      <img src={StarIcon2} className="lp-xtm-usecase-icon" alt="lp-xtm-usecase-icon" />
                      <p id="lp-xtm-usecase-title">Community Governance</p>
                    </div>
                    <p>
                      We believe in decentralizing platform governance over time, giving you—our community—a say in
                      Torum's growth and evolution.
                    </p>

                    <p>
                      For starters, while the initial waves of NFT collections onboarded onto Torum will be handpicked
                      by our team, $XTM holders will soon get to decide which collections they want to see on Torum in
                      the near future!
                    </p>

                    <p>More opportunities for community governance will be coming soon. Stay tuned.</p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="lp-xtm-usecase-container-wrapper grid-third">
                  <img src={EngageReward} className="lp-xtm-usecase-img" alt="lp-xtm-usecase-img" />
                  <div className="lp-xtm-usecase-description-wrapper third">
                    <div className="lp-xtm-usecase-title-wrapper">
                      <img src={StarIcon3} className="lp-xtm-usecase-icon" alt="lp-xtm-usecase-icon" />
                      <p id="lp-xtm-usecase-title">Engage & Reward with $XTM</p>
                    </div>
                    <p>
                      As an $XTM holder, you can purchase unique gifts to reward your favourite content creators,
                      showing your appreciation and support while keeping creators motivated to produce more content
                      you'll love.
                    </p>

                    <p>
                      Plus, you're not just showing a token of appreciation—you're building a vibrant, interconnected
                      community on Torum! These interactions lay the foundation for a strong social network, enhancing
                      bonds and enriching the overall experience for everyone on Torum.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="lp-xtm-oxtm-container">
            <div className="lp-xtm-oxtm-desc-wrapper">
              <p className="lp-xtm-oxtm-title">
                Introducing <span id="bold">oXTM</span>
              </p>
              <p>
                oXTM is an option token that allows Torum users to purchase $XTM on-chain at a discounted rate using a
                combination of oXTM and Ethereum.
              </p>
              <p>
                You can acquire oXTM by converting your Pixels—obtained from our in-app point system that measures your
                contribution to Torum V2. Earn Pixels by maintaining streaks and successfully achieving Collective
                Milestones within your NFT community.
              </p>
            </div>

            <img src={OxtmImg} className="lp-xtm-oxtm-img" alt="lp-xtm-oxtm-img" />
          </div>

          <div className="lp-xtm-get-container">
            <p className="lp-xtm-get-title">
              Here’s How You Can Get <span>$XTM</span>
            </p>

            <div className="lp-xtm-get-wrapper">
              <a
                href="https://www.kucoin.com/trade/XTM-USDT"
                className="get-xtm-link"
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => {
                  Mixpanel.track(LP_XTM_KuCoin)
                }}
              >
                <div className="lp-xtm-get-panel">
                  <img src={KucoinIcon} className="lp-xtm-get-img" alt="lp-xtm-get-img" />
                  <div className="desc-wrapper">
                    <p id="panel-title">KuCoin</p>
                    <p id="panel-text">KuCoin Exchange</p>
                  </div>
                </div>
              </a>

              <a
                href="https://www.htx.com/trade/xtm_usdt"
                className="get-xtm-link"
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => {
                  Mixpanel.track(LP_XTM_HTX)
                }}
              >
                <div className="lp-xtm-get-panel">
                  <img src={HtxIcon} className="lp-xtm-get-img" alt="lp-xtm-get-img" />
                  <div className="desc-wrapper">
                    <p id="panel-title">HTX</p>
                    <p id="panel-text">HTX Exchange</p>
                  </div>
                </div>
              </a>

              <a
                href="https://www.mexc.com/exchange/XTM_USDT"
                className="get-xtm-link"
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => {
                  Mixpanel.track(LP_XTM_MEXC)
                }}
              >
                <div className="lp-xtm-get-panel">
                  <img src={MexcIcon} className="lp-xtm-get-img" alt="lp-xtm-get-img" />
                  <div className="desc-wrapper">
                    <p id="panel-title">MEXC</p>
                    <p id="panel-text">MEXC Exchange</p>
                  </div>
                </div>
              </a>

              <a
                href="https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x443cAb9583B83eAA7A712c9D64525E57E2a7eB3f"
                className="get-xtm-link"
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => {
                  Mixpanel.track(LP_XTM_PancakeSwap)
                }}
              >
                <div className="lp-xtm-get-panel">
                  <img src={PancakeIcon} className="lp-xtm-get-img" alt="lp-xtm-get-img" />
                  <div className="desc-wrapper">
                    <p id="panel-title">PancakeSwap</p>
                    <p id="panel-text"> We’re on PancakeSwap as well.</p>
                  </div>
                </div>
              </a>

              {/* <a href="#" className="get-xtm-link"> target="_blank"*/}
              <div className="lp-xtm-get-panel">
                <img src={OxtmIcon} className="lp-xtm-get-img unavailable" alt="lp-xtm-get-img" />
                <div className="desc-wrapper unavailable">
                  <p id="panel-title">oXTM</p>
                  <p id="panel-text">Have oXTM? Get $XTM at a discount!</p>
                </div>
                <div className="lp-xtm-get-coming-soon">Coming Soon</div>
              </div>
              {/* </a> */}

              {/* <a href="#" className="get-xtm-link"> target="_blank" */}
              <div className="lp-xtm-get-panel ">
                <img src={ArtisanIcon} className="lp-xtm-get-img unavailable" alt="lp-xtm-get-img" />
                <div className="desc-wrapper unavailable">
                  <p id="panel-title">Become an ARtisan</p>
                  <p id="panel-text">Contribute to the Creator Community.</p>
                </div>
                <div className="lp-xtm-get-coming-soon">Coming Soon</div>
              </div>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default XTM
