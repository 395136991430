import { readContract } from "wagmi/actions"

import { wagmiActionsConfig } from "../web3/config"

import CHUBBY_CUBS_ABI from "../contracts/CHUBBY_CUBS_ABI.json"
import { CHUBBY_CUBS_CONTRACT_ADDRESS } from "../web3/tokens"

// export async function getOwnedTokenIdListFromContract(address) {
//   try {
//     const ownedTokenIdList = await readContract(wagmiActionsConfig, {
//       abi: CHUBBY_CUBS_ABI,
//       address: CHUBBY_CUBS_CONTRACT_ADDRESS,
//       functionName: "walletOfOwner",
//       args: [address],
//     })

//     return ownedTokenIdList
//   } catch (error) {
//     console.error(error, "error from getOwnedTokenIdListFromContract")
//   }
// }

export async function getOwnedTokenIdListFromContract(address, retries = 10, delay = 5000) {
  const attempt = async (n) => {
    try {
      // console.log("contract read attempt #", retries - n + 1)

      const ownedTokenIdList = await readContract(wagmiActionsConfig, {
        abi: CHUBBY_CUBS_ABI,
        address: CHUBBY_CUBS_CONTRACT_ADDRESS,
        // functionName: n > 3 ? "INTENTIONAL_INCORRECT_FUNCTION_NAME_FOR_TEST_PURPOSE" : "walletOfOwner",
        functionName: "walletOfOwner",
        args: [address],
      })

      return ownedTokenIdList
    } catch (error) {
      if (n === 1) {
        console.error(error, "error from getOwnedTokenIdListFromContract")
        throw error // rethrow the error after the final retry
      }
      console.warn(`Contract Read Attempt ${retries - n + 1} failed. Retrying in ${delay}ms...`)
      await new Promise((resolve) => setTimeout(resolve, delay))
      return attempt(n - 1)
    }
  }

  return attempt(retries)
}


/**
 * @returns nftv2.cdn.torum.team/ and will have to append tokenId.json
 * once jsons are loaded on IPFS, this will return the IPFS link and at the end, need to append /tokenId
 */
export async function getMetadataBaseURIFromContract() {
  try {
    const metadataBaseURI = await readContract(wagmiActionsConfig, {
      abi: CHUBBY_CUBS_ABI,
      address: CHUBBY_CUBS_CONTRACT_ADDRESS,
      functionName: "baseTokenURI",
    })
    return metadataBaseURI
  } catch (error) {
    console.error(error, "error from getMetadataBaseURIFromContract")
  }
}
