import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  whitelist: ["global", "user"], //slices you want to persist
};

export default persistConfig;
