import mixpanel from "mixpanel-browser"

const isProd = process.env.REACT_APP_ENV === "production" || true


mixpanel.init(process.env.REACT_APP_MIXPANEL, {debug: isProd, track_pageview: true, persistence: 'localStorage'})


let actions = {
  identify: (id) => {
    isProd && mixpanel.identify(id)
  },
  alias: (id) => {
    isProd && mixpanel.alias(id)
  },
  track: (name, props) => {
    isProd && mixpanel.track(name, props)
  },
  reset: () => {
    isProd && mixpanel.reset()
  },
  people: {
    set: (props) => {
      isProd && mixpanel.people.set(props)
    },
  },
}

export let Mixpanel = actions
