export const Founders = [
  {
    name: "CS Go",
    position: "Founder, CEO",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "Nelthan",
    position: "Co-founder, Operations",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  {
    name: "Jayson",
    position: "Co-founder, Marketing",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },

  {
    name: "Alwin",
    position: "Co-founder, Design",
    userId: "yfenggo",
    imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  },
  // {
  //   name: "Teddy Tan",
  //   position: "Chief Financial Officer",
  //   userId: "yfenggo",
  //   imageUrl: "https://ca.slack-edge.com/TRJMB6ZSB-U02HCG1T2LU-38713040df46-512",
  // },
]
