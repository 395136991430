import { Tech } from "./tech-list"

import TechNft from "../../../assets/tech-nft.png"

import "./styles.scss"

function TechCard() {
  return (
    <div className="wrapper">
      {Tech.map((member) => {
        return (
          <div className="team-members-card">
            <img src={TechNft} className="member-pfp" alt="member-pfp" />
            <div className="member-detail-wrapper">
              <div className="member-name">{member.name}</div>
              <div className="member-position">{member.position}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
export default TechCard
