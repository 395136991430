import React, { useState } from "react"

import { ReactComponent as PlusIcon } from "../../assets/svg/plus-icon.svg"
import { ReactComponent as MinusIcon } from "../../assets/svg/minus-icon.svg"

import "./Accordion.scss"
import { Mixpanel } from "../../utils/mixpanel"
import { other_source } from "../../utils/mixpanel/mixpanelActionTypes"

function Accordion({ list }) {
  const [activeIndex, setActiveIndex] = useState(Array.from(Array(list.length)))

  function toggleOpen(index) {
    const current = [].concat(activeIndex)
    if (current[index] === true) {
      current[index] = false
    } else {
      current[index] = true
    }
    setActiveIndex(current)
  }

  return (
    <div className="accordion">
      {list.map((item, i) => {
        const isActive = activeIndex[i] === true

        return (
          <div key={i} className={`accordion-item ${isActive ? "open" : ""}`}
               onClick={() =>{
                 toggleOpen(i)
                 Mixpanel.track(`LP_Home_FAQ${i+1}`)
               } }>
            <div className="accordion-item-header">
              {isActive ? (
                <MinusIcon className="accordion-item-header-icon" />
              ) : (
                <PlusIcon className="accordion-item-header-icon" />
              )}
              <p className="accordion-item-header-title">{item.title}</p>
            </div>

            <div className="accordion-item-description-wrapper">
              <div className="accordion-item-description">
                {item.description.map((paragraph, j) => {
                  return <p key={j} className="accordion-item-text">{paragraph}</p>
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Accordion
