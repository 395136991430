export function shortenWalletAddress(address) {
  if (typeof address !== "string" || address.length !== 42) {
    return "Invalid address"
  }

  const firstPart = address.substring(0, 4)
  const lastPart = address.substring(address.length - 4)

  return firstPart + "..." + lastPart
}

export function shortenTxHash(hash) {
  if (typeof hash !== "string") {
    return "Invalid hash"
  }

  const firstPart = hash.substring(0, 11)
  const lastPart = hash.substring(hash.length - 14)

  return firstPart + "..." + lastPart
}