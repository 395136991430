import "swiper/css"
import "swiper/css/pagination"
import "./Carousel.scss"

import { Mousewheel, Pagination } from "swiper/modules"
import React, { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Mixpanel } from "../../utils/mixpanel"
import {
  direct,
  LP_Home_DigitalBuddies,
  LP_Home_DigitalIdentity, LP_Home_JPEG,
  LP_Home_Tales, other_source
} from "../../utils/mixpanel/mixpanelActionTypes"

/**
 * This carousel handles horizontal & vertical scrolls simultaneously
 * - scrolls image horizontally (on navigation click only)
 * - scrolls content vertically (on navigation click & user scroll manually)
 * @param {Array} list - Carousel content: { title: "", description: ["", ""], imageUrl: "", paginationIcon: "" }
 * @param {Boolean} autoplay - true to start carousel animation on mount, default false
 * @returns
 */

function Carousel({ list = [], autoplay = false }) {
  const imageRef = useRef()
  const ref = useRef()

  const width = useRef(0)

  const [activeIndex, setActiveIndex] = useState(0)
  const [left, setLeft] = useState(0)

  useEffect(() => {
    if (imageRef.current) {
      const t = setTimeout(()=> {
        width.current = imageRef.current.offsetWidth
        clearTimeout(t)
      },[100]) // minor delay

    }
  }, [imageRef])

  // set autoplay
  useEffect(() => {
    let timer
    if (autoplay) {
      let counter = 0
      timer = setInterval(() => {
        if (counter === list.length - 1) {
          counter = 0
        } else {
          counter++
        }
        setActiveIndex(counter)
      }, 5000)
    }

    return () => {
      if (autoplay) {
        clearInterval(timer)
      }
    }
  }, [autoplay])

  useEffect(()=>{

    const Mixpanel_Event_Name={
      0:LP_Home_DigitalBuddies,
      1:LP_Home_Tales,
      2:LP_Home_DigitalIdentity,
      3:LP_Home_JPEG,

    }
    Mixpanel.track(`${Mixpanel_Event_Name[activeIndex]}`)
  },[activeIndex])

  useEffect(() => {
    setLeft(activeIndex * width.current)
    ref.current.swiper.slideTo(activeIndex)
  }, [activeIndex])

  return (
    <section className="carousel">
      <div className="carousel-container">
        <div className="carousel-viewport" >
          {list.map((item, i) => {
            const className = activeIndex === i ? "active" : null
            return (
              <div
                key={i}
                className={`carousel-slide ${className}`}
                style={{
                  "--left": `${-left}px`,
                }}
              >
                <img ref={imageRef} src={item.imageUrl} alt="carousel-slide-img" />
              </div>
            )
          })}
        </div>

        <Swiper
          ref={ref}
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={36}
          mousewheel={true}
          pagination={{
            clickable: false,
          }}
          modules={[Mousewheel, Pagination]}
          onSlideChange={({ activeIndex }) => {
            setActiveIndex(activeIndex)
          }}
          autoHeight
        >
          {list.map((item, i) => {
            const className = activeIndex === i ? "active" : null
            return (
              <SwiperSlide className={`carousel-slide ${className}`} key={i}>
                <h1 className="carousel-slide-title noselect">{item.title}</h1>
                <div className="carousel-slide-description">
                  {item.description.map((item, i) => {
                    return (
                      <p key={i} className="noselect">
                        {item}
                      </p>
                    )
                  })}
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>

      <ol className="carousel-navigation">
        {list.map(({ paginationIcon: Icon }, i) => {
          const className = activeIndex === i ? "active" : null
          return (
            <li
              key={i}
              className={`carousel-navigation-item ${className}`}
              onClick={() => {
                setActiveIndex(i)
              }}
            >
              <Icon />
            </li>
          )
        })}
      </ol>
    </section>
  )
}

export default Carousel
