import React from "react"

import Accordion from "../../../components/Accordion/Accordion"

import { ReactComponent as ChevronRightIcon } from "../../../assets/svg/chevron-right-icon.svg"

import "./styles.scss"
import { Mixpanel } from "../../../utils/mixpanel"
import { ROUTES } from "../../../constants/navigation"
import { LP_Home_FAQ3_Form, LP_Home_FAQ3_Here } from "../../../utils/mixpanel/mixpanelActionTypes"

const faqs = [
  {
    title: "What's available inside the Torum V2 app?",
    description: [
      "Torum V2 is the place for anyone—whether you're just dipping your toes into crypto, an NFT collector, or a content creator. You must be wondering, 'But why should I join Torum?' Well, here's what sets us apart from other platforms.",
      "Introducing Torum V2's content creation tool; it comes with NFT AR Face Filters that allow you to bring your NFTs to life, adding unprecedented experiences and ways to socialize with others. Essentially, Torum V2's ecosystem is built to help realize the full potential of NFTs as a social tool that forms and nurtures communities.",
    ],
  },
  {
    title: "What are AR Filters on Torum V2?",
    description: [
      "As the owner of an NFT AR Face Filter, you will have the opportunity to bring your NFTs to life! Each 3D-modelled filter is meticulously crafted using Blender and Unity, based on all the specific traits of your particular NFT. This taps into the untapped potential of your NFTs being the ultimate social tool that you can utilize to maintain pseudo-anonymity while building your Web3 identity and interacting with like-minded individuals in a meaningful way.",
      "But wait, there’s more! Owning one of these NFT AR Face Filters provides you early access into the SocialFi ecosystem of Torum V2, while giving you an opportunity to be a pioneer of this exciting new world that pushes the limits of utility for NFTs.",
    ],
  },
  {
    title: "Who are eligible to join Torum V2?",
    description: [
      "To land on Torum V2, you’ll need to own an AR Face Filter. If you own an NFT from any of the whitelisted NFT collections like Pudgy Penguins, Moonbirds, Meebits, Sappy Seals, or Cool Cats—you’re in! You are eligible to claim unique AR filters that inherit all the specific traits of your original NFTs. Upon claiming, you’ll receive a unique QR code. This code will be utilized to complete your Torum V2 registration process.",
      <>
        What about Torum V1 users you ask? The team has personally handpicked (based on a set of criteria) a bunch of
        active users to receive a whitelist spot, allowing these users to mint a Chubby Cubs NFT for free. The list of
        users can be viewed{" "}
        <a
          rel="noreferrer noopener"
          href="https://docs.google.com/spreadsheets/d/1_2zb7UTKq_jPkY1xPwCMtv8dnCT-mfY856k98hfBJdI/edit#gid=0"
          target="_blank"
          className="text-bold text-primary"
          onClick={()=>{
            Mixpanel.track(LP_Home_FAQ3_Here)
          }}
        >
          here
        </a>
        .  If you don’t see your Torum username in the list, please fill up this 
        <a
          rel="noreferrer noopener"
          href="https://bsu5ua93efj.typeform.com/to/CqTXTybp"
          target="_blank"
          className="text-bold text-primary"
          onClick={()=>{
            Mixpanel.track(LP_Home_FAQ3_Form)
          }}
        >
          form
        </a>
        . These exclusive NFTs will come with their own unique AR filter that allows you to join Torum V2.
      </>,
    ],
  },
  {
    title: "When will Torum V2 be available?",
    description: [
      "We totally get your excitement to join us! Although Torum V2 is still in the works, we’re putting everything into making sure it's absolutely fantastic for you. In the meantime, relax and stay tuned. Hang tight and keep an eye on our social media for the latest updates. We can’t wait to welcome you to Torum V2, see you there soon!",
    ],
  },
]

function FAQ() {
  return (
    <section className="lp-section lp-faq-container">
      <div className="lp-faq-wrapper">
        <div className="lp-section-header">
          <h1 className="lp-heading">Frequently Asked Questions</h1>
        </div>

        <Accordion list={faqs} />
      </div>

      {/* <div className="lp-section-footer">
        <div className="lp-link-wrapper">
          <a className="lp-link" href="#">
            For more FAQs
          </a>
          <ChevronRightIcon />
        </div>
      </div> */}
    </section>
  )
}

export default FAQ
