import { createWeb3Modal } from "@web3modal/wagmi/react"
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config"

import { switchChain, getAccount } from "@wagmi/core"
import { WagmiProvider, createConfig, http } from "wagmi"
import { sepolia, blast, blastSepolia } from "wagmi/chains"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID

const metadata = {
  name: "Torum V2",
  description: "Torum - Connecting Crypto Enthusiasts!",
  url: "https://torum.com", // origin must match your domain & subdomain
  icons: ["https://assets.coingecko.com/coins/images/18393/large/torum-transparent-cmc.png"],
}

export const SEPOLIA_CHAIN_ID = sepolia.id
export const BLAST_CHAIN_ID = blast.id
export const BLAST_SEPOLIA_CHAIN_ID = blastSepolia.id

function getTransportsByEnv() {
  if (process.env.REACT_APP_ENV === "production") {
    return {
      [blast.id]: http(),
    }
  }

  return {
    // [blastSepolia.id]: http(),
    [sepolia.id]: http(),
  }
}

export function getChainsByEnv() {
  if (process.env.REACT_APP_ENV === "production") {
    return [blast]
  }

  // return [blastSepolia]
  return [sepolia]
}

export function isSupportedNetwork(chainId) {
  const chains = getChainsByEnv()

  if (chains[0]?.id === chainId) {
    if (chainId === SEPOLIA_CHAIN_ID) {
      return true
    }

    if (chainId === BLAST_SEPOLIA_CHAIN_ID) {
      return true
    }

    if (chainId === BLAST_CHAIN_ID) {
      return true
    }
  }

  return false
}

export const wagmiActionsConfig = createConfig({
  chains: getChainsByEnv(),
  transports: getTransportsByEnv(),
})

const wagmiConfig = defaultWagmiConfig({
  chains: getChainsByEnv(),
  projectId,
  metadata,
  // ...wagmiOptions, // Optional - Override createConfig parameters
})

export async function checkAndSwitchToSupportedNetwork() {
  try {
    const account = getAccount(wagmiConfig)
    const connectedChainId = account?.chainId

    if (isSupportedNetwork(connectedChainId)) {
      return Promise.resolve()
    } else {
      await switchChain(wagmiConfig, {
        chainId: process.env.REACT_APP_ENV === "production" ? BLAST_CHAIN_ID : SEPOLIA_CHAIN_ID,
      })
    }
  } catch (error) {
    return Promise.reject("error from checkAndSwitchToSupportedNetwork")
  }
}

export function initWeb3Modal() {
  createWeb3Modal({
    wagmiConfig,
    projectId,
    enableAnalytics: false, // Optional - defaults to your Cloud configuration
    enableOnramp: true, // Optional - false as default
    themeMode: "light",
    themeVariables: {
      "--w3m-font-family": "Inter",
      // '--w3m-accent-color': '',
      // '--w3m-accent': '',
      // '--w3m-color-mix': '',
      // '--w3m-color-mix-strength': Number,
      // '--w3m-font-size-master': '',
      // '--w3m-border-radius-master': '',
      // '--w3m-z-index': ''
    },
    defaultChain: process.env.REACT_APP_ENV === "production" ? blast : sepolia, // blastSepolia
    allowUnsupportedChain: false,
    featuredWalletIds: [
      "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // metamask
      "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4", // binance
      "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", // trust
      "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369", // rainbow
      "ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18", // zerion
      "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709", // okx
      "18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1", // rabby
      "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393", // phantom
    ],
    // allWallets: "HIDE",
    // adding wallets inside this will make them appear inside All Wallet menu.
    // includeWalletIds: [],
    excludeWalletIds: [
      "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa", // coinbase
    ],
  })
}

export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
