import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

// export const { setSomeData } = globalSlice.actions;

export default globalSlice.reducer;
