import React from "react"

import VideoWebM from "../../../assets/landing-page-hero.webm"
import VideoMp4 from "../../../assets/landing-page-hero.mp4"

import MouseClick from "../../../assets/svg/whitepaper-click-icon.svg"

import "./styles.scss"
import { Mixpanel } from "../../../utils/mixpanel"
import { LP_Home_Whitepaper } from "../../../utils/mixpanel/mixpanelActionTypes"

function Hero() {
  return (
    <section className="lp-home-hero">
      <div className="lp-home-hero-video-container">
        <video autoPlay="autoplay" playsInline={true} loop={true} muted={true}>
          <source src={VideoWebM} type="video/webm" />
          {/* note: webm video fails to play on Safari -> fallback to mp4 */}
          <source src={VideoMp4} type="video/mp4" />
        </video>
      </div>

      <div className="lp-section lp-home-hero-header">
        <a
          href="https://docs.torum.com/torum-v2-whitepaper-1"
          onClick={() => {
            Mixpanel.track(LP_Home_Whitepaper)
          }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="lp-home-hero-whitepaper">
            Whitepaper 2.0
            <img src={MouseClick} className="mouse-click-whitepaper-icon" alt="mouse-click-whitepaper-icon" />
          </div>
        </a>

        <div className="lp-home-hero-heading">
          Bring Your <br /> NFT To Life
        </div>

        <div className="lp-home-hero-subheading">Because it’s more than just a JPEG—it’s your virtual presence.</div>
      </div>
    </section>
  )
}

export default Hero
