import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistConfig from "./persistConfig";
import userSlice from "./slices/userSlice";
import globalSlice from "./slices/globalSlice";

const combinedReducer = combineReducers({
  global: globalSlice,
  user: userSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "store/reset") {
    //onlogout, empty out the state
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const serializableCheck = {
  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck }),
});

export const persistor = persistStore(store);
