import React from "react"

import ReactModal from "react-modal"

ReactModal.setAppElement("#root")

const customStylesLight = {
  content: {
    top: "calc(50%)",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "26px",
    border: "none",
    // overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(234, 234, 234, 0.6)",
    backdropFilter: "blur(10px)",
    zIndex: "1000",
  },
}

// Note: DO NOT conditionally call the modal.
// The close transition will work only when the modal is open/closed using isOpen prop.
// more info here: https://reactcommunity.org/react-modal/styles/transitions/

function Modal({ isOpen, onRequestClose, children }) {
  return (
    <ReactModal
      id="app-modal"
      contentLabel="modalA"
      closeTimeoutMS={200}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStylesLight}
      shouldFocusAfterRender={true}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onAfterOpen={() => {
        document.body.style.overflow = "hidden"
      }}
      onAfterClose={() => {
        document.body.style.overflowY = "auto"
        document.body.style.overflowX = "hidden"
      }}
    >
      {children}
    </ReactModal>
  )
}

export default Modal
